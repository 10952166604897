import React from "react"
import Layout from "../components/layouts/Layout.js"
import { graphql, useStaticQuery } from "gatsby"

const W9 = () => {
  const { prismicW9 } = useStaticQuery(graphql`
    query W9Query {
      prismicW9 {
        data {
          w9 {
            url
          }
        }
      }
    }
  `)

  if (!prismicW9?.data?.w9?.url) {
    return <div>Error loading W9 data</div>
  }

  return (
    <Layout>
      <div style={{ width: "100vw", height: "100vh" }}>
        <iframe
          src={prismicW9.data.w9.url}
          title="W9 Form"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
        />
      </div>
    </Layout>
  )
}

export default W9
